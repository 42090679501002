import React, { useEffect, useState } from "react"
import { FacebookMessengerShareButton } from "react-share"
import styled from "styled-components"
import Head from "../components/Head"
import Layout from "../components/Layout"
import SEO from "../components/SEO"

const PageLayout = styled.div`
  max-width: 1000px;
  margin: 0 auto;
  padding: 1rem;

  li {
    padding: 0.5rem;
  }
`

const StyledInput = styled.input`
  width: 70%;
  margin: 15px;
`
const StyledSelect = styled.select`
  width: 70%;
  margin: 15px;
`

const StyledLabel = styled.label`
  width: 150px;
  text-align: left;
  display: inline-block;
  margin: 15px;
`
const StyledButton = styled.button`
  padding: 0.5rem;
  margin: 1rem;
  color: white;
  border: none;
  cursor: pointer;
`

const PrinterButton = styled.button`
  padding: 0.5rem;
  text-align: center;
  background-color: blue;
  color: white;
  border: none;
  margin: 0.5rem auto;
  cursor: pointer;
  &:hover {
    background-color: lightskyblue;
  }
`

const BackToFormButton = styled.button`
  padding: 0.5rem;
  text-align: center;
  background-color: red;
  color: white;
  border: none;
  margin: 0.5rem auto;
  cursor: pointer;
  &:hover {
    background-color: lightskyblue;
  }
`

const ResponseModal = styled.div`
  width: 310px;
  left: 50%;
  margin-left: -170px;
  padding: 1rem;
  position: fixed;
  background-color: white;
  border: 1px solid black;
  z-index: 3;
  bottom: 400px;
  box-shadow: 5px 10px 10px #888;
  font-size: 1.3em;

  i {
    color: red;
    position: absolute;
    right: 5px;
    top: 5px;
    font-size: 2em;
    cursor: pointer;
    &:hover {
      color: lightsalmon;
    }
  }
`

const FormModal = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  /* z-index: 3; */
  padding: 1rem 0;
`

const MachineRegistrationPage = () => {
  const [formData, setFormData] = useState({
    endUserCompany: "",
    endUserContact: "",
    endUserJobDescription: "",
    endUserAddress: "",
    endUserCity: "",
    endUserState: "",
    endUserCountry: "",
    endUserZip: "",
    endUserPhone: "",
    endUserEmail: "",
    serial: "",
    model: "",
    terms: "",
    terms2: "",
    signature2: "",
    installingDealer: "",
    installBranch: "",
    masterDistributor: "",
    installMonth: "",
    installDay: "",
    installYear: "",
    installedBy: "",
    installedByEmail: "",
    signature: "",
  })
  const [modelNumber, setModelNumbers] = useState([])
  const [formResponse, setFormResponse] = useState({})
  const [displayFormModal, setDisplayFormModal] = useState("none")
  const [viewModal, setViewModal] = useState("none")
  const [successMessage, setSuccessMessage] = useState(
    "Thanks!  We have received your Machine Registration submission and will begin processing it."
  )

  function handleBackToForm() {
    if (formResponse.success) {
      window.location.reload()
      setViewModal("none")
    } else {
      setViewModal("none")
      setDisplayFormModal("none")
    }
  }

  function restForm(e) {
    e.preventDefault()
    setFormData({
      endUserCompany: "",
      endUserContact: "",
      endUserJobDescription: "",
      endUserAddress: "",
      endUserCity: "",
      endUserState: "",
      endUserCountry: "",
      endUserZip: "",
      endUserPhone: "",
      endUserEmail: "",
      serial: "",
      model: "",
      terms: "",
      terms2: "",
      signature2: "",
      installingDealer: "",
      installBranch: "",
      masterDistributor: "",
      installMonth: "",
      installDay: "",
      installYear: "",
      installedBy: "",
      installedByEmail: "",
      signature: "",
    })
  }

  function testFormData(e) {
    e.preventDefault()
    setFormData({
      endUserCompany: "test company",
      endUserContact: "test user",
      endUserJobDescription: "test job",
      endUserAddress: "321 Main Street",
      endUserCity: "Racine",
      endUserState: "WI",
      endUserCountry: "USA",
      endUserZip: "53402",
      endUserPhone: "262-498-5555",
      endUserEmail: "jason@test.com",
      serial: "102151",
      model: "175-29FC",
      terms: "on",
      terms2: "on",
      signature2: "test sig",
      installingDealer: "test dealer",
      installBranch: "test branch",
      masterDistributor: "test disti",
      installMonth: "02",
      installDay: "13",
      installYear: "2006",
      installedBy: "test",
      installedByEmail: "jason@test.com",
      signature: "test sig",
    })
  }

  function handleChange(e) {
    const value = e.target.value
    setFormData({
      ...formData,
      [e.target.name]: value,
    })
  }

  const CORS_PROXY = "https://factorycat-proxy.herokuapp.com/?q="
  const url = `https://www.tomcatequip.com/processRegistrations.php?serial=`
  const modelNumberUrl =
    "https://www.tomcatequip.com/processRegistrations.php?action=getModels&brand=FACTORYCAT"

  async function handleSubmit(e) {
    e.preventDefault()
    const response = await fetch(
      `${CORS_PROXY}https://www.tomcatequip.com/processRegistrations.php?serial=${formData.serial}&` +
        new URLSearchParams({
          endUserCompany: formData.endUserCompany,
          endUserContact: formData.endUserContact,
          endUserJobDescription: formData.endUserJobDescription,
          endUserAddress: formData.endUserAddress,
          endUserCity: formData.endUserCity,
          endUserState: formData.endUserState,
          endUserCountry: formData.endUserCountry,
          endUserZip: formData.endUserZip,
          endUserPhone: formData.endUserPhone,
          endUserEmail: formData.endUserEmail,
          serial: formData.serial,
          model: formData.model,
          terms: formData.terms,
          terms2: formData.terms2,
          signature2: formData.signature2,
          installingDealer: formData.installingDealer,
          installBranch: formData.installBranch,
          masterDistributor: formData.masterDistributor,
          installMonth: formData.installMonth,
          installDay: formData.installDay,
          installYear: formData.installYear,
          installedBy: formData.installedBy,
          installedByEmail: formData.installedByEmail,
          signature: formData.signature,
        })
    )
    response.json().then(response => setFormResponse(response))
    console.log(response)

    setViewModal("block")
    setDisplayFormModal("block")
  }

  async function handleModelNumbers() {
    const response = await fetch(`${CORS_PROXY}${modelNumberUrl}`)
    response.json().then(response => setModelNumbers(response))
    console.log(response)
  }

  useEffect(() => {
    handleModelNumbers()
  }, [])

  return (
    <Layout>
      <SEO title="machine-registration" />
      <Head title="machine-registration" />

      <ResponseModal style={{ display: viewModal }}>
        {/* <i
          onClick={() => setViewModal("none")}
          className="far fa-times-circle"
        /> */}
        {formResponse.success && successMessage}
        {formResponse.failure && formResponse.failure}

        <br />
        <a
          href={`http://www.tomcatequip.com/machineRegistrationPrinter.html?serial=${formData.serial}`}
          target="_blank"
          rel="noreferrer"
        >
          <PrinterButton>
            Click Here for a Printer Friendly Version
          </PrinterButton>
        </a>

        <BackToFormButton onClick={handleBackToForm}>
          Back to Form
        </BackToFormButton>
      </ResponseModal>
      <FormModal style={{ display: displayFormModal }}></FormModal>
      <PageLayout>
        <h1>Machine Registration</h1>
        {/* <button onClick={testFormData}>Test Data</button> */}
        <h3>
          <span style={{ color: "red" }}>*</span> required{" "}
        </h3>
        <form
          id="myForm"
          onSubmit={handleSubmit}
          name="factoryCat-registration-form"
        >
          <StyledLabel for="company-name">
            Company Name:<span style={{ color: "red" }}>*</span>
          </StyledLabel>
          <StyledInput
            value={formData.endUserCompany}
            onChange={handleChange}
            name="endUserCompany"
            required
          />
          <br />
          <StyledLabel for="contact-name">
            Contact Name:<span style={{ color: "red" }}>*</span>
          </StyledLabel>
          <StyledInput
            value={formData.endUserContact}
            onChange={handleChange}
            name="endUserContact"
            required
          />
          <br />
          <StyledLabel for="contact-title">
            Contact Title:<span style={{ color: "red" }}>*</span>
          </StyledLabel>
          <StyledInput
            value={formData.endUserJobDescription}
            name="endUserJobDescription"
            onChange={handleChange}
            required
          />
          <br />
          <StyledLabel for="address">
            Address:<span style={{ color: "red" }}>*</span>
          </StyledLabel>
          <StyledInput
            value={formData.endUserAddress}
            name="endUserAddress"
            onChange={handleChange}
            required
          />
          <br />
          <StyledLabel for="city-state">
            City/State:<span style={{ color: "red" }}>*</span>
          </StyledLabel>
          <StyledInput
            value={formData.endUserCity}
            name="endUserCity"
            onChange={handleChange}
            style={{ width: "200px" }}
            required
          />
          <StyledSelect
            onChange={handleChange}
            value={formData.endUserState}
            name="endUserState"
            style={{ width: "200px" }}
          >
            <option value="Choose">Choose a State</option>
            <option value="AL">Alabama</option>
            <option value="AK">Alaska</option>
            <option value="AZ">Arizona</option>
            <option value="AR">Arkansas</option>
            <option value="CA">California</option>
            <option value="CO">Colorado</option>
            <option value="CT">Connecticut</option>
            <option value="DE">Delaware</option>
            <option value="DC">District Of Columbia</option>
            <option value="FL">Florida</option>
            <option value="GA">Georgia</option>
            <option value="HI">Hawaii</option>
            <option value="ID">Idaho</option>
            <option value="IL">Illinois</option>
            <option value="IN">Indiana</option>
            <option value="IA">Iowa</option>
            <option value="KS">Kansas</option>
            <option value="KY">Kentucky</option>
            <option value="LA">Louisiana</option>
            <option value="ME">Maine</option>
            <option value="MD">Maryland</option>
            <option value="MA">Massachusetts</option>
            <option value="MI">Michigan</option>
            <option value="MN">Minnesota</option>
            <option value="MS">Mississippi</option>
            <option value="MO">Missouri</option>
            <option value="MT">Montana</option>
            <option value="NE">Nebraska</option>
            <option value="NV">Nevada</option>
            <option value="NH">New Hampshire</option>
            <option value="NJ">New Jersey</option>
            <option value="NM">New Mexico</option>
            <option value="NY">New York</option>
            <option value="NC">North Carolina</option>
            <option value="ND">North Dakota</option>
            <option value="OH">Ohio</option>
            <option value="OK">Oklahoma</option>
            <option value="OR">Oregon</option>
            <option value="PA">Pennsylvania</option>
            <option value="RI">Rhode Island</option>
            <option value="SC">South Carolina</option>
            <option value="SD">South Dakota</option>
            <option value="TN">Tennessee</option>
            <option value="TX">Texas</option>
            <option value="UT">Utah</option>
            <option value="VT">Vermont</option>
            <option value="VA">Virginia</option>
            <option value="WA">Washington</option>
            <option value="WV">West Virginia</option>
            <option value="WI">Wisconsin</option>
            <option value="WY">Wyoming</option>
          </StyledSelect>
          <br />
          <StyledLabel for="city-state">
            Country:<span style={{ color: "red" }}>*</span>
          </StyledLabel>
          <StyledSelect
            onChange={handleChange}
            value={formData.endUserCountry}
            name="endUserCountry"
            required
          >
            <option value="USA">United States</option>
            <option value="AFG">Afghanistan</option>
            <option value="ALA">Åland Islands</option>
            <option value="ALB">Albania</option>
            <option value="DZA">Algeria</option>
            <option value="ASM">American Samoa</option>
            <option value="AND">Andorra</option>
            <option value="AGO">Angola</option>
            <option value="AIA">Anguilla</option>
            <option value="ATA">Antarctica</option>
            <option value="ATG">Antigua and Barbuda</option>
            <option value="ARG">Argentina</option>
            <option value="ARM">Armenia</option>
            <option value="ABW">Aruba</option>
            <option value="AUS">Australia</option>
            <option value="AUT">Austria</option>
            <option value="AZE">Azerbaijan</option>
            <option value="BHS">Bahamas</option>
            <option value="BHR">Bahrain</option>
            <option value="BGD">Bangladesh</option>
            <option value="BRB">Barbados</option>
            <option value="BLR">Belarus</option>
            <option value="BEL">Belgium</option>
            <option value="BLZ">Belize</option>
            <option value="BEN">Benin</option>
            <option value="BMU">Bermuda</option>
            <option value="BTN">Bhutan</option>
            <option value="BOL">Bolivia, Plurinational State of</option>
            <option value="BES">Bonaire, Sint Eustatius and Saba</option>
            <option value="BIH">Bosnia and Herzegovina</option>
            <option value="BWA">Botswana</option>
            <option value="BVT">Bouvet Island</option>
            <option value="BRA">Brazil</option>
            <option value="IOT">British Indian Ocean Territory</option>
            <option value="BRN">Brunei Darussalam</option>
            <option value="BGR">Bulgaria</option>
            <option value="BFA">Burkina Faso</option>
            <option value="BDI">Burundi</option>
            <option value="KHM">Cambodia</option>
            <option value="CMR">Cameroon</option>
            <option value="CAN">Canada</option>
            <option value="CPV">Cape Verde</option>
            <option value="CYM">Cayman Islands</option>
            <option value="CAF">Central African Republic</option>
            <option value="TCD">Chad</option>
            <option value="CHL">Chile</option>
            <option value="CHN">China</option>
            <option value="CXR">Christmas Island</option>
            <option value="CCK">Cocos (Keeling) Islands</option>
            <option value="COL">Colombia</option>
            <option value="COM">Comoros</option>
            <option value="COG">Congo</option>
            <option value="COD">Congo, the Democratic Republic of the</option>
            <option value="COK">Cook Islands</option>
            <option value="CRI">Costa Rica</option>
            <option value="CIV">Côte d'Ivoire</option>
            <option value="HRV">Croatia</option>
            <option value="CUB">Cuba</option>
            <option value="CUW">Curaçao</option>
            <option value="CYP">Cyprus</option>
            <option value="CZE">Czech Republic</option>
            <option value="DNK">Denmark</option>
            <option value="DJI">Djibouti</option>
            <option value="DMA">Dominica</option>
            <option value="DOM">Dominican Republic</option>
            <option value="ECU">Ecuador</option>
            <option value="EGY">Egypt</option>
            <option value="SLV">El Salvador</option>
            <option value="GNQ">Equatorial Guinea</option>
            <option value="ERI">Eritrea</option>
            <option value="EST">Estonia</option>
            <option value="ETH">Ethiopia</option>
            <option value="FLK">Falkland Islands (Malvinas)</option>
            <option value="FRO">Faroe Islands</option>
            <option value="FJI">Fiji</option>
            <option value="FIN">Finland</option>
            <option value="FRA">France</option>
            <option value="GUF">French Guiana</option>
            <option value="PYF">French Polynesia</option>
            <option value="ATF">French Southern Territories</option>
            <option value="GAB">Gabon</option>
            <option value="GMB">Gambia</option>
            <option value="GEO">Georgia</option>
            <option value="DEU">Germany</option>
            <option value="GHA">Ghana</option>
            <option value="GIB">Gibraltar</option>
            <option value="GRC">Greece</option>
            <option value="GRL">Greenland</option>
            <option value="GRD">Grenada</option>
            <option value="GLP">Guadeloupe</option>
            <option value="GUM">Guam</option>
            <option value="GTM">Guatemala</option>
            <option value="GGY">Guernsey</option>
            <option value="GIN">Guinea</option>
            <option value="GNB">Guinea-Bissau</option>
            <option value="GUY">Guyana</option>
            <option value="HTI">Haiti</option>
            <option value="HMD">Heard Island and McDonald Islands</option>
            <option value="VAT">Holy See (Vatican City State)</option>
            <option value="HND">Honduras</option>
            <option value="HKG">Hong Kong</option>
            <option value="HUN">Hungary</option>
            <option value="ISL">Iceland</option>
            <option value="IND">India</option>
            <option value="IDN">Indonesia</option>
            <option value="IRN">Iran, Islamic Republic of</option>
            <option value="IRQ">Iraq</option>
            <option value="IRL">Ireland</option>
            <option value="IMN">Isle of Man</option>
            <option value="ISR">Israel</option>
            <option value="ITA">Italy</option>
            <option value="JAM">Jamaica</option>
            <option value="JPN">Japan</option>
            <option value="JEY">Jersey</option>
            <option value="JOR">Jordan</option>
            <option value="KAZ">Kazakhstan</option>
            <option value="KEN">Kenya</option>
            <option value="KIR">Kiribati</option>
            <option value="PRK">Korea, Democratic People's Republic of</option>
            <option value="KOR">Korea, Republic of</option>
            <option value="KWT">Kuwait</option>
            <option value="KGZ">Kyrgyzstan</option>
            <option value="LAO">Lao People's Democratic Republic</option>
            <option value="LVA">Latvia</option>
            <option value="LBN">Lebanon</option>
            <option value="LSO">Lesotho</option>
            <option value="LBR">Liberia</option>
            <option value="LBY">Libya</option>
            <option value="LIE">Liechtenstein</option>
            <option value="LTU">Lithuania</option>
            <option value="LUX">Luxembourg</option>
            <option value="MAC">Macao</option>
            <option value="MKD">
              Macedonia, the former Yugoslav Republic of
            </option>
            <option value="MDG">Madagascar</option>
            <option value="MWI">Malawi</option>
            <option value="MYS">Malaysia</option>
            <option value="MDV">Maldives</option>
            <option value="MLI">Mali</option>
            <option value="MLT">Malta</option>
            <option value="MHL">Marshall Islands</option>
            <option value="MTQ">Martinique</option>
            <option value="MRT">Mauritania</option>
            <option value="MUS">Mauritius</option>
            <option value="MYT">Mayotte</option>
            <option value="MEX">Mexico</option>
            <option value="FSM">Micronesia, Federated States of</option>
            <option value="MDA">Moldova, Republic of</option>
            <option value="MCO">Monaco</option>
            <option value="MNG">Mongolia</option>
            <option value="MNE">Montenegro</option>
            <option value="MSR">Montserrat</option>
            <option value="MAR">Morocco</option>
            <option value="MOZ">Mozambique</option>
            <option value="MMR">Myanmar</option>
            <option value="NAM">Namibia</option>
            <option value="NRU">Nauru</option>
            <option value="NPL">Nepal</option>
            <option value="NLD">Netherlands</option>
            <option value="NCL">New Caledonia</option>
            <option value="NZL">New Zealand</option>
            <option value="NIC">Nicaragua</option>
            <option value="NER">Niger</option>
            <option value="NGA">Nigeria</option>
            <option value="NIU">Niue</option>
            <option value="NFK">Norfolk Island</option>
            <option value="MNP">Northern Mariana Islands</option>
            <option value="NOR">Norway</option>
            <option value="OMN">Oman</option>
            <option value="PAK">Pakistan</option>
            <option value="PLW">Palau</option>
            <option value="PSE">Palestinian Territory, Occupied</option>
            <option value="PAN">Panama</option>
            <option value="PNG">Papua New Guinea</option>
            <option value="PRY">Paraguay</option>
            <option value="PER">Peru</option>
            <option value="PHL">Philippines</option>
            <option value="PCN">Pitcairn</option>
            <option value="POL">Poland</option>
            <option value="PRT">Portugal</option>
            <option value="PRI">Puerto Rico</option>
            <option value="QAT">Qatar</option>
            <option value="REU">Réunion</option>
            <option value="ROU">Romania</option>
            <option value="RUS">Russian Federation</option>
            <option value="RWA">Rwanda</option>
            <option value="BLM">Saint Barthélemy</option>
            <option value="SHN">
              Saint Helena, Ascension and Tristan da Cunha
            </option>
            <option value="KNA">Saint Kitts and Nevis</option>
            <option value="LCA">Saint Lucia</option>
            <option value="MAF">Saint Martin (French part)</option>
            <option value="SPM">Saint Pierre and Miquelon</option>
            <option value="VCT">Saint Vincent and the Grenadines</option>
            <option value="WSM">Samoa</option>
            <option value="SMR">San Marino</option>
            <option value="STP">Sao Tome and Principe</option>
            <option value="SAU">Saudi Arabia</option>
            <option value="SEN">Senegal</option>
            <option value="SRB">Serbia</option>
            <option value="SYC">Seychelles</option>
            <option value="SLE">Sierra Leone</option>
            <option value="SGP">Singapore</option>
            <option value="SXM">Sint Maarten (Dutch part)</option>
            <option value="SVK">Slovakia</option>
            <option value="SVN">Slovenia</option>
            <option value="SLB">Solomon Islands</option>
            <option value="SOM">Somalia</option>
            <option value="ZAF">South Africa</option>
            <option value="SGS">
              South Georgia and the South Sandwich Islands
            </option>
            <option value="SSD">South Sudan</option>
            <option value="ESP">Spain</option>
            <option value="LKA">Sri Lanka</option>
            <option value="SDN">Sudan</option>
            <option value="SUR">Suriname</option>
            <option value="SJM">Svalbard and Jan Mayen</option>
            <option value="SWZ">Swaziland</option>
            <option value="SWE">Sweden</option>
            <option value="CHE">Switzerland</option>
            <option value="SYR">Syrian Arab Republic</option>
            <option value="TWN">Taiwan, Province of China</option>
            <option value="TJK">Tajikistan</option>
            <option value="TZA">Tanzania, United Republic of</option>
            <option value="THA">Thailand</option>
            <option value="TLS">Timor-Leste</option>
            <option value="TGO">Togo</option>
            <option value="TKL">Tokelau</option>
            <option value="TON">Tonga</option>
            <option value="TTO">Trinidad and Tobago</option>
            <option value="TUN">Tunisia</option>
            <option value="TUR">Turkey</option>
            <option value="TKM">Turkmenistan</option>
            <option value="TCA">Turks and Caicos Islands</option>
            <option value="TUV">Tuvalu</option>
            <option value="UGA">Uganda</option>
            <option value="UKR">Ukraine</option>
            <option value="ARE">United Arab Emirates</option>
            <option value="GBR">United Kingdom</option>
            <option value="UMI">United States Minor Outlying Islands</option>
            <option value="URY">Uruguay</option>
            <option value="UZB">Uzbekistan</option>
            <option value="VUT">Vanuatu</option>
            <option value="VEN">Venezuela, Bolivarian Republic of</option>
            <option value="VNM">Viet Nam</option>
            <option value="VGB">Virgin Islands, British</option>
            <option value="VIR">Virgin Islands, U.S.</option>
            <option value="WLF">Wallis and Futuna</option>
            <option value="ESH">Western Sahara</option>
            <option value="YEM">Yemen</option>
            <option value="ZMB">Zambia</option>
            <option value="ZWE">Zimbabwe</option>
          </StyledSelect>
          <StyledLabel for="zip">
            Zip Code:<span style={{ color: "red" }}>*</span>
          </StyledLabel>
          <StyledInput
            onChange={handleChange}
            value={formData.endUserZip}
            style={{ width: "200px" }}
            name="endUserZip"
            required
          />
          <br />
          <StyledLabel for="phone">
            Phone Number:<span style={{ color: "red" }}>*</span>
          </StyledLabel>
          <StyledInput
            onChange={handleChange}
            value={formData.endUserPhone}
            style={{ width: "300px" }}
            name="endUserPhone"
            placeholder="123-123-1234"
            required
          />
          <br />
          <StyledLabel for="email">
            Email:<span style={{ color: "red" }}>*</span>
          </StyledLabel>
          <StyledInput
            onChange={handleChange}
            value={formData.endUserEmail}
            type="email"
            style={{ width: "300px" }}
            name="endUserEmail"
            required
          />
          <br />
          <StyledLabel for="serial-no">
            Serial Number:<span style={{ color: "red" }}>*</span>
          </StyledLabel>
          <StyledInput
            onChange={handleChange}
            value={formData.serial}
            style={{ width: "300px" }}
            name="serial"
            required
          />
          <br />
          <span style={{ color: "red" }}>
            {formResponse.serialNo && formResponse.serialNo}
          </span>
          <br />
          <StyledLabel for="model-no">
            Model Number:<span style={{ color: "red" }}>*</span>
          </StyledLabel>
          <StyledSelect
            onChange={handleChange}
            value={formData.model}
            style={{ width: "300px" }}
            name="model"
            required
          >
            {modelNumber.map(numbers => {
              return (
                <option
                  value={numbers[0]}
                >{`${numbers[1]} | ${numbers[0]}`}</option>
              )
            })}
          </StyledSelect>
          <h4>Terms of Acceptance:</h4>
          <ul>
            <li>
              I have received copies of the Operators and Parts manuals
              applicable to this machine and understand that they contain
              important information about the safe use and proper maintenance of
              this machine.
            </li>
            <li>
              I understand that all operators must read, understand and follow
              the instructions in the Operators Manual, and agree they will be
              trained in the safe use of this machine in accordance with the
              warnings and instructions provided on the machine and contained in
              the Operator’s Manual provided with the machine prior to operating
              it.
            </li>
            <li>
              I am authorized to make the foregoing acknowledgments on behalf of
              the End User indicated above and on behalf of my company am
              authorized to submit this Warranty Registration.
            </li>
          </ul>
          <h4 style={{ margin: "0" }}>
            End User Representative (Electronic Signature)
            <span style={{ color: "red", margin: "0" }}>*</span>
          </h4>
          <StyledInput
            onChange={handleChange}
            value={formData.signature2}
            style={{ margin: "0" }}
            name="signature2"
          />
          <br />
          <input
            onChange={handleChange}
            type="checkbox"
            name="terms"
            checked={formData.terms}
          />
          I understand that checking this box constitutes a legal signature
          confirming that I acknowledge and agree to the above Terms of
          Acceptance.
          <br />
          <br />
          <br />
          <StyledLabel for="install-disti">
            Installing Distributor:<span style={{ color: "red" }}>*</span>
          </StyledLabel>
          <StyledInput
            onChange={handleChange}
            value={formData.installingDealer}
            name="installingDealer"
            required
          />
          <StyledLabel for="install-disti-branch">
            Installing Distributor Branch:
            <span style={{ color: "red" }}>*</span>
            <br />
          </StyledLabel>
          <StyledInput
            onChange={handleChange}
            value={formData.installBranch}
            name="installBranch"
            required
          />
          <StyledLabel for="install-disti-branch">
            Master Distributor:
            <br />
            <span style={{ fontSize: ".7em" }}>(International Only)</span>
          </StyledLabel>
          <StyledInput
            onChange={handleChange}
            value={formData.masterDistributor}
            name="masterDistributor"
          />
          <StyledLabel>Install Date:</StyledLabel>
          <StyledSelect
            onChange={handleChange}
            value={formData.installMonth}
            name="installMonth"
            style={{ width: "100px" }}
          >
            <option>Month</option>
            <option value="01">January</option>
            <option value="02">February</option>
            <option value="03">March</option>
            <option value="04">April</option>
            <option value="05">May</option>
            <option value="06">June</option>
            <option value="07">July</option>
            <option value="08">August</option>
            <option value="09">September</option>
            <option value="10">October</option>
            <option value="11">November</option>
            <option value="12">December</option>
          </StyledSelect>
          <StyledSelect
            onChange={handleChange}
            value={formData.installDay}
            name="installDay"
            style={{ width: "100px" }}
          >
            <option value="day">Day</option>
            <option value="01">01</option>
            <option value="02">02</option>
            <option value="03">03</option>
            <option value="04">04</option>
            <option value="05">05</option>
            <option value="06">06</option>
            <option value="07">07</option>
            <option value="08">08</option>
            <option value="09">09</option>
            <option value="10">10</option>
            <option value="11">11</option>
            <option value="12">12</option>
            <option value="13">13</option>
            <option value="14">14</option>
            <option value="15">15</option>
            <option value="16">16</option>
            <option value="17">17</option>
            <option value="18">18</option>
            <option value="19">19</option>
            <option value="20">20</option>
            <option value="21">21</option>
            <option value="22">22</option>
            <option value="23">23</option>
            <option value="24">24</option>
            <option value="25">25</option>
            <option value="26">26</option>
            <option value="27">27</option>
            <option value="28">28</option>
            <option value="29">29</option>
            <option value="30">30</option>
            <option value="31">31</option>
          </StyledSelect>
          <StyledSelect
            onChange={handleChange}
            value={formData.installYear}
            name="installYear"
            style={{ width: "100px" }}
          >
            <option value="year">Year</option>
            <option value="2022">2022</option>
            <option value="2021">2021</option>
            <option value="2020">2020</option>
            <option value="2019">2019</option>
            <option value="2018">2018</option>
            <option value="2017">2017</option>
            <option value="2016">2016</option>
            <option value="2015">2015</option>
            <option value="2014">2014</option>
            <option value="2013">2013</option>
            <option value="2012">2012</option>
            <option value="2011">2011</option>
            <option value="2010">2010</option>
            <option value="2009">2009</option>
            <option value="2008">2008</option>
            <option value="2007">2007</option>
            <option value="2006">2006</option>
            <option value="2005">2005</option>
            <option value="2004">2004</option>
            <option value="2003">2003</option>
            <option value="2002">2002</option>
            <option value="2001">2001</option>
            <option value="2000">2000</option>
          </StyledSelect>
          <br />
          <StyledLabel for="installed-by-name">
            Installing By Name:<span style={{ color: "red" }}>*</span>
          </StyledLabel>
          <StyledInput
            onChange={handleChange}
            value={formData.installedBy}
            name="installedBy"
            required
          />
          <StyledLabel for="installed-by-email">
            Installing By Email:<span style={{ color: "red" }}>*</span>
          </StyledLabel>
          <StyledInput
            onChange={handleChange}
            value={formData.installedByEmail}
            name="installedByEmail"
            type="email"
            required
          />
          <br />
          <h4 style={{ margin: "0" }}>
            Installed By (Electronic Signature)
            <span style={{ color: "red", margin: "0" }}>*</span>
          </h4>
          <StyledInput
            onChange={handleChange}
            value={formData.signature}
            style={{ margin: "0" }}
            name="signature"
          />
          <br />
          <input
            onChange={handleChange}
            type="checkbox"
            checked={formData.terms2}
            name="terms2"
          />
          I understand I understand that checking this box consitutes a legal
          signature confirming that I installed the piece of equipment with the
          End User indicated above and provided them training and manuals.
          <br />
          <StyledButton type="submit" style={{ backgroundColor: "green" }}>
            Submit Registration
          </StyledButton>
          <StyledButton
            onClick={restForm}
            type="reset"
            style={{ backgroundColor: "red" }}
          >
            Reset Form
          </StyledButton>
        </form>
      </PageLayout>
    </Layout>
  )
}

export default MachineRegistrationPage
